import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SupportGuidesProps } from '../types';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      width: '95%',
      fontSize: '14px',
      marginBottom: '8px',
    },
  }),
);

function SupportGuides(props: SupportGuidesProps) {
  const classes = useStyles();

  const getSupportIcon = () => {
    switch (props.type) {
      case 'informational':
        return <InfoIcon fontSize="small" />;
      case 'emotional':
        return <EmojiEmotionsIcon fontSize="small" />;
      case 'esteem':
        return <FavoriteIcon fontSize="small" />;
      default:
        return <GroupIcon fontSize="small" />;
    }
  };

  return (
    <Alert
      icon={getSupportIcon()}
      color={props.type !== 'informational' ? 'info' : 'warning'}
      className={classes.alert}>
      {props.content}
    </Alert>
  );
}

export default SupportGuides;
