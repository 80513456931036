import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PostData, CommentData, ReactionData, Order, PSGProps, ChipStatus, CheckBoxes } from './types';
import { functions, RootState } from '../../store';
import { formatFacebookTimestamp } from '../../utils/timeFormatter';
import { stableSort, getComparator } from './sortHelper';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import AddTopicDialog from './Dialogs/AddTopicDialog';
import AddCommentDialog from './Dialogs/AddCommentDialog';
import StaticChipsArray from '../StaticChipsArray';
import CommentCell from './Cells/CommentCell';
import CustomizedToggle from '../CustomizedToggle';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LinkIcon from '@material-ui/icons/Link';
import { exportGroupCSVFile } from '../../utils/exportCSV';
import { getPostLink } from './utils';

function createPostData(
  id: string,
  message: string,
  userName: string,
  updatedAt: string,
  noOfComments: number,
  noOfReactions: number,
  topics: string[],
  comments: CommentData[],
  reactions: ReactionData[],
  link: string,
): PostData {
  return { id, message, userName, updatedAt, noOfComments, noOfReactions, topics, comments, reactions, link };
}

function createCommentData(
  id: string,
  message: string,
  userName: string,
  updatedAt: string,
  noOfComments: number,
  noOfReactions: number,
  topics: string[],
  link: string,
): CommentData {
  return { id, message, userName, updatedAt, noOfComments, noOfReactions, topics, link };
}

function createReactionData(userName: string, type: string): ReactionData {
  return { userName, type };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      borderRadius: '20px',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 600,
    },
    tableRow: {
      fontSize: '12px',
      '&.Mui-selected:hover': {
        backgroundColor: '#f5f5f5',
      },
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
    },
    tableCell: {
      fontSize: '12px',
    },
    expandCell: {
      minWidth: '60px',
      maxWidth: '60px',
      margin: 0,
      padding: 0,
    },
    messageCell: {
      minWidth: '220px',
    },
    iconCell: {
      minWidth: '180px',
      maxWidth: '180px',
      margin: 0,
      padding: 0,
    },
    iconButton: {
      marginLeft: '4px',
      '&&&:before': {
        textDecoration: 'none !important',
      },
    },
    caption: {
      padding: 8,
      fontSize: '14px',
    },
    toolbar: {
      '& > p:nth-of-type(2)': {
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    menuItem: {
      fontSize: '14px',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 24,
      marginLeft: -12,
    },
    badge: {
      color: 'white',
    },
    alert: {
      fontSize: '14px',
    },
    filterPaper: {
      width: '100%',
      borderRadius: '20px',
      padding: theme.spacing(4),
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
    switchLabel: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  }),
);

const lastUpdateToggles = ['ALL', '< 1 DAY', '< 3 DAYS', '< 7 DAYS', '< 14 DAYS'];

const PSG: React.FC<PSGProps> = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const firestore = useFirestore();

  // Bind Firebase Metadata
  useFirestoreConnect(() => `config/meta`);
  const metaData = useSelector((state: RootState) => state.firestore.data.config && state.firestore.data.config.meta);

  // Bind Topics Messages
  useFirestoreConnect('topics');
  const topicRecords = useSelector((state: RootState) => state.firestore.data.topics);

  // Bind Support Mapping
  useFirestoreConnect('support');
  const supportRecords = useSelector((state: RootState) => state.firestore.data.support);

  const id = localStorage.getItem('groupId');

  // Bind Facebook Names
  useFirestoreConnect('fbMapping');
  const fbNames = useSelector((state: RootState) => state.firestore.data.fbMapping);

  const [rows, setRows] = React.useState<PostData[]>([]);
  const [totalSize, setTotalSize] = React.useState(0);
  const [loadedIdx, setLoadedIdx] = React.useState(0);
  const [open, setOpen] = React.useState<string[]>([]);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof PostData>('updatedAt');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tagOpen, setTagOpen] = React.useState(false);
  const [tagRow, setTagRow] = React.useState<PostData | CommentData | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [commentOpen, setCommentOpen] = React.useState(false);
  const [commentRow, setCommentRow] = React.useState<PostData | CommentData | null>(null);
  const [commentLoading, setCommentLoading] = React.useState(false);
  const [lastUpdateToggle, setLastUpdateToggle] = React.useState('ALL');
  const [alert, setAlert] = React.useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<'success' | 'error' | 'warning' | 'info'>('success');

  React.useEffect(() => {
    setPage(0);
    setLoadedIdx(0);
    getPostsData(true);
  }, [id]);

  React.useEffect(() => {
    setPage(0);
    setLoadedIdx(0);
    getPostsData(true);
  }, [lastUpdateToggle]);

  const setRowOpen = (id: string) => {
    const idx = open.indexOf(id);
    const tmpOpen = [...open];
    if (idx >= 0) tmpOpen.splice(idx, 1);
    else tmpOpen.push(id);

    setOpen(tmpOpen);
  };

  const handleRequestSort = (property: keyof PostData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
    let tmpIdx = loadedIdx;
    if (newPage > tmpIdx) {
      setLoadedIdx(newPage);
      await getPostsData();
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const download = () => {
    exportGroupCSVFile(rows, id, topicRecords, fbNames);
  };

  // onClick event for the manage tag action to open the Tag dialog
  const onClickTag = (row: PostData) => {
    setTagOpen(true);
    setTagRow(row);
  };

  // onClick event for the publish comment action to open the Comment dialog
  const onClickComment = (row: PostData | CommentData) => {
    setCommentOpen(true);
    setCommentRow(row);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const handleTagClose = () => {
    setTagOpen(false);
  };

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  // Handle comment sending by calling Firebase HTTP Callable Function
  const handleCommentSend = async (str: string) => {
    setCommentLoading(true);

    if (functions.postFacebookCommentFn && commentRow) {
      const queryObj = {
        id: commentRow.id,
        content: str,
        adminEmail: firebase.auth().currentUser?.email,
      };

      const result: any = await functions.postFacebookCommentFn(queryObj);
      setAlertOpen(true);
      setAlert(result.data.message);
      if (result.data.status === 200) setSeverity('success');
      else setSeverity('error');
    }

    setCommentOpen(false);
    setCommentLoading(false);
  };

  const updateSupport = async (checkboxStatus: CheckBoxes) => {
    if (commentRow)
      await firestore
        .collection('support')
        .doc(commentRow.id)
        .set({ support: checkboxStatus });
  };

  const updateChipStatus = async (item: ChipStatus) => {
    if (tagRow) {
      if (item.added)
        await firestore
          .collection('topics')
          .doc(tagRow.id)
          .update({ topics: firestore.FieldValue.arrayRemove(item.value) });
      else {
        // Check topic document existence
        const topicExist = (await firestore.doc(`topics/${tagRow.id}`).get()).exists;

        // If document exist, add the topic to the topics array
        if (topicExist)
          await firestore
            .collection('topics')
            .doc(tagRow.id)
            .update({ topics: firestore.FieldValue.arrayUnion(item.value) });
        // Otherwise, set the topics array with the new topic identified
        else
          await firestore
            .collection('topics')
            .doc(tagRow.id)
            .set({ topics: [item.value] });
      }
    }
  };

  const addNewTopic = async (str: string) => {
    if (str.length > 0) {
      if (tagRow) {
        const topicExist = (await firestore.doc(`topics/${tagRow.id}`).get()).exists;

        if (topicExist)
          await firestore
            .collection('topics')
            .doc(tagRow.id)
            .update({ topics: firestore.FieldValue.arrayUnion(str) });
        else
          await firestore
            .collection('topics')
            .doc(tagRow.id)
            .set({ topics: [str] });
      }

      await firestore
        .collection('config')
        .doc('meta')
        .update({ topics: firestore.FieldValue.arrayUnion(str) });
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getSelectedTopics = (id?: string) => {
    if (id && topicRecords && Object.keys(topicRecords) && Object.keys(topicRecords).indexOf(id) >= 0)
      return topicRecords[id].topics;
    return [];
  };

  const getSupportRecords = (id?: string) => {
    if (id && supportRecords && Object.keys(supportRecords) && Object.keys(supportRecords).indexOf(id) >= 0)
      return supportRecords[id].support;
    return {
      Informational: true,
      Emotional: false,
      Esteem: false,
      Network: false,
    };
  };

  const onClickCommentTag = (commentRow: CommentData) => {
    setTagOpen(true);
    setTagRow(commentRow);
  };

  const handleLastUpdateToggle = (lastUpdate: string) => {
    if (lastUpdate && lastUpdate !== lastUpdateToggle) {
      setLastUpdateToggle(lastUpdate);
    }
  };

  const getFollowupTarget = () => {
    let date = new Date();
    let dateTarget;

    switch (lastUpdateToggle) {
      case '< 1 DAY':
        dateTarget = date.setDate(date.getDate() - 1);
        break;
      case '< 3 DAYS':
        dateTarget = date.setDate(date.getDate() - 3);
        break;
      case '< 7 DAYS':
        dateTarget = date.setDate(date.getDate() - 7);
        break;
      case '< 14 DAYS':
        dateTarget = date.setDate(date.getDate() - 14);
        break;
      default:
        dateTarget = date.setDate(date.getDate() - 1);
        break;
    }

    return new Date(dateTarget).toISOString().split('.')[0] + '+0000';
  };

  const getFbUserName = (id: string) => {
    if (fbNames && fbNames[id]) return fbNames[id].userName;
  };

  const getPostsData = async (isInitial?: boolean) => {
    setLoading(true);

    let query = firestore
      .collection(`groups/${id}/posts`)
      .orderBy('updatedAt', 'desc')
      .limit(rowsPerPage);

    if (!isInitial) {
      const lastDoc = await firestore.doc(`groups/${id}/posts/${rows[rows.length - 1].id}`).get();
      if (lastDoc.exists) query = query.startAfter(lastDoc);
    }

    if (lastUpdateToggle && lastUpdateToggle !== 'ALL') {
      const dateRef = getFollowupTarget();
      query = query.where('updatedAt', '>=', dateRef);
      const size = (
        await firestore
          .collection(`groups/${id}/posts`)
          .where('updatedAt', '>=', dateRef)
          .get()
      ).size;
      setTotalSize(size);
    } else {
      const size = (await firestore.collection(`groups/${id}/posts`).get()).size;
      setTotalSize(size);
    }

    const posts = await query.get();

    const tmpRows: PostData[] = [];

    const commentPromises: Promise<any>[] = [];
    const reactionPromises: Promise<any>[] = [];
    posts.docs.forEach(async post => {
      commentPromises.push(firestore.collection(`groups/${id}/posts/${post.id}/comments`).get());
      reactionPromises.push(firestore.collection(`groups/${id}/posts/${post.id}/reactions`).get());
    });

    const commentDocs = await Promise.all(commentPromises);
    const reactionDocs = await Promise.all(reactionPromises);

    for (let i = 0; i < posts.size; i++) {
      const post = posts.docs[i];
      const { message, userName, updatedAt, topics } = post.data();
      let linkStr = getPostLink(post.id.split('_')[0], post.id.split('_')[1]);

      const tmpComments: CommentData[] = [];
      const comments = commentDocs[i];
      const tmpReactions: ReactionData[] = [];
      const reactions = reactionDocs[i];
      let totalComments = comments.size;
      let totalReactions = reactions.size;

      comments.docs.forEach((comment: any) => {
        const { userName, message, updatedAt, topics, comment_count, like_count } = comment.data();
        totalComments += comment_count;
        totalReactions += like_count;

        tmpComments.push(
          createCommentData(
            comment.id,
            message,
            userName ? userName : '',
            updatedAt,
            comment_count,
            like_count,
            topics,
            `${linkStr}/comments/${comment.id}`,
          ),
        );
      });

      reactions.docs.forEach((reaction: any) => {
        const { userName, type } = reaction.data();
        tmpReactions.push(createReactionData(userName, type));
      });

      tmpRows.push(
        createPostData(
          post.id,
          message,
          userName ? userName : '',
          updatedAt,
          totalComments,
          totalReactions,
          topics ? topics : [],
          tmpComments,
          tmpReactions,
          linkStr,
        ),
      );
    }

    if (isInitial) setRows(tmpRows);
    else setRows([...rows, ...tmpRows]);

    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.filterPaper}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={6} container justify="center" alignContent="center">
            <Typography className={classes.switchLabel}>Filter by Last Update Time</Typography>
          </Grid>
          <Grid item xs={12} md={6} container justify="center" alignContent="center">
            <CustomizedToggle
              toggleLabel={lastUpdateToggles}
              toggleValue={lastUpdateToggle}
              handleToggleChange={handleLastUpdateToggle}></CustomizedToggle>
          </Grid>
        </Grid>
      </Paper>

      <AddTopicDialog
        open={tagOpen}
        row={tagRow}
        selected={getSelectedTopics(tagRow?.id)}
        topics={metaData && metaData.topics}
        handleTagClose={handleTagClose}
        updateChipStatus={updateChipStatus}
        addNewTopic={addNewTopic}
      />

      <AddCommentDialog
        open={commentOpen}
        row={commentRow}
        topics={getSelectedTopics(commentRow?.id)}
        loading={commentLoading}
        supports={metaData && metaData.supports}
        supportRecords={getSupportRecords(commentRow?.id)}
        handleCommentClose={handleCommentClose}
        handleCommentSend={handleCommentSend}
        updateSupport={updateSupport}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar download={download} />
        {loading && <CircularProgress size={48} className={classes.buttonProgress} />}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        hover
                        onClick={() => handleClick(row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.tableRow}>
                        <TableCell className={classes.expandCell}>
                          <Grid container spacing={0}>
                            <Grid item xs={12} container justify="center">
                              <Tooltip title={row.comments.length <= 0 ? '' : 'View Comments'}>
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    disabled={row.comments.length <= 0}
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setRowOpen(row.id)}>
                                    {open.indexOf(row.id) >= 0 ? <KeyboardArrowUpIcon /> : <ChatBubbleOutlineIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                        {/* <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                          {row.id.replace('_', '-')}
                        </TableCell> */}
                        <TableCell className={clsx(classes.tableCell, classes.messageCell)}>{row.message}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.userName ? row.userName : getFbUserName(row.id)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{formatFacebookTimestamp(row.updatedAt)}</TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          <Badge
                            badgeContent={row.noOfComments}
                            color="secondary"
                            showZero
                            classes={{ badge: classes.badge }}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          <Badge
                            badgeContent={row.noOfReactions}
                            color="secondary"
                            showZero
                            classes={{ badge: classes.badge }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <StaticChipsArray data={getSelectedTopics(row.id)} />
                        </TableCell>
                        <TableCell className={classes.iconCell}>
                          <Grid container spacing={0}>
                            <Grid item xs={4}>
                              <Tooltip title="Manage Topics">
                                <IconButton
                                  className={classes.iconButton}
                                  color="primary"
                                  onClick={() => onClickTag(row)}>
                                  <AddCircleIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={4}>
                              <Tooltip title="Post Comment">
                                <IconButton
                                  className={classes.iconButton}
                                  color="primary"
                                  onClick={() => onClickComment(row)}>
                                  <RateReviewIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={4}>
                              <Tooltip title="View on Facebook">
                                <IconButton
                                  className={classes.iconButton}
                                  color="primary"
                                  onClick={() => window.open(row.link)}>
                                  <LinkIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>

                      <TableRow key={`${row.id}_comments`}>
                        <CommentCell
                          open={open}
                          groupId={id}
                          row={row}
                          comments={row.comments}
                          getFbUserName={getFbUserName}
                          onClickCommentTag={onClickCommentTag}
                          onClickComment={onClickComment}
                          getSelectedTopics={getSelectedTopics}
                        />
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalSize}
          rowsPerPage={rowsPerPage}
          page={page}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
            menuItem: classes.menuItem,
          }}
          style={{ fontSize: '14px' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={<Typography style={{ fontSize: '14px' }}>Dense Padding</Typography>}
      />

      <Snackbar open={alertOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" className={classes.alert}>
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PSG;
