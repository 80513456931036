import * as React from 'react';
import { ChipsArrayProps } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: '14px',
      color: 'white',
    },
    chipIcon: {
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        color: 'white',
      },
    },
  }),
);

function ChipsArray(props: ChipsArrayProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.data &&
        props.data.map(item => {
          return (
            props.added === item.added && (
              <li key={item.value}>
                <Chip
                  clickable
                  color="primary"
                  label={item.value}
                  className={classes.chip}
                  deleteIcon={
                    props.added ? (
                      <CancelIcon className={classes.chipIcon} />
                    ) : (
                      <AddCircleIcon className={classes.chipIcon} />
                    )
                  }
                  onDelete={() => props.updateChipStatus(item)}
                />
              </li>
            )
          );
        })}
    </div>
  );
}

export default ChipsArray;
