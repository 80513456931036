import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SupportCheckBoxProps } from '../types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    checkboxLabel: {
      fontSize: '14px',
    },
  }),
);

function SupportCheckBox(props: SupportCheckBoxProps) {
  const classes = useStyles();

  return (
    <FormGroup row>
      {props.supports.map(support => {
        return (
          <FormControlLabel
            key={support}
            control={
              <Checkbox
                checked={props.checkbox[support]}
                size="small"
                onChange={props.handleChange}
                name={support}
                color="primary"
              />
            }
            label={<Typography className={classes.checkboxLabel}>{support}</Typography>}
          />
        );
      })}
    </FormGroup>
  );
}

export default SupportCheckBox;
