import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PostData, HeadCell, Order } from './types';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerIcon: {
      color: '#212121',
      '&:hover': {
        color: '#757575',
      },
    },
  }),
);

const headCells: HeadCell[] = [
  // { id: 'id', numeric: false, disablePadding: true, label: 'Post ID' },
  { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
  { id: 'userName', numeric: false, disablePadding: false, label: 'User' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Post_Updated_At' },
  { id: 'noOfComments', numeric: true, disablePadding: false, label: '#Comments' },
  { id: 'noOfReactions', numeric: true, disablePadding: false, label: '#Reactions' },
];

interface EnhancedTableProps {
  onRequestSort: (property: keyof PostData) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: keyof PostData) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '60px' }} />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'noOfComments' || headCell.id === 'noOfReactions' ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.id === 'noOfComments' ? (
                <Grid container direction="column">
                  <ChatBubbleOutlineIcon fontSize="small" className={classes.headerIcon} />
                </Grid>
              ) : headCell.id === 'noOfReactions' ? (
                <Grid container direction="column">
                  <FavoriteBorderIcon fontSize="small" className={classes.headerIcon} />
                </Grid>
              ) : (
                headCell.label
              )}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" style={{ paddingRight: '32px', fontSize: '14px', fontWeight: 'bold' }}>
          Topics
        </TableCell>
        <TableCell align="center" style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
