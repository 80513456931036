import * as React from 'react';
import { AddTagDialogProps, ChipStatus } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ChipsArray from './ChipArray';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      fontSize: '16px',
      fontStyle: 'italic',
      marginBottom: theme.spacing(4),
    },
    appBar: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    title: {
      color: '#fff',
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    input: {
      fontSize: '14px',
    },
    label: {
      fontSize: '16px',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
  }),
);

function AddTopicDialog(props: AddTagDialogProps) {
  const classes = useStyles();

  const [topicContent, setTopicContent] = React.useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTopicContent(event.target.value);
  };

  const getChipsArray = () => {
    if (!props.topics) return [];

    const mappedTopics: ChipStatus[] = props.topics.map(topic => {
      if (props.selected) {
        if (props.selected.indexOf(topic) >= 0) return { added: true, value: topic };
        return { added: false, value: topic };
      }
      return { added: false, value: topic };
    });
    return mappedTopics;
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleTagClose}
        fullScreen
        keepMounted
        aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Manage Topics
            </Typography>

            <IconButton edge="end" onClick={props.handleTagClose} style={{ color: '#fff' }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DialogContentText className={classes.dialogContent}>{`“${props.row?.message}”`}</DialogContentText>

          <Typography variant="body2" className={classes.subtitle}>
            Exitsing Topics
          </Typography>
          <ChipsArray data={getChipsArray()} added={false} updateChipStatus={item => props.updateChipStatus(item)} />
          <Divider className={classes.divider} />

          <Typography variant="body2" className={classes.subtitle}>
            Create New Topics
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                multiline
                variant="outlined"
                onChange={onChange}
                margin="dense"
                id="name"
                label="New Topic"
                fullWidth
                value={topicContent}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={2} container justify="center">
              <Tooltip title="Create Topic">
                <IconButton
                  color="primary"
                  onClick={() => {
                    props.addNewTopic(topicContent);
                    setTopicContent('');
                  }}>
                  <LibraryAddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />

          <Typography variant="body2" className={classes.subtitle}>
            Identified Topics
          </Typography>
          <ChipsArray data={getChipsArray()} added={true} updateChipStatus={item => props.updateChipStatus(item)} />
          <Divider className={classes.divider} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddTopicDialog;
