import * as React from 'react';
import { AddCommentDialogProps } from '../types';
import { colors } from '../../../styles/variables';
import StaticChipsArray from '../../StaticChipsArray';
import SupportCheckBox from './SupportCheckbox';
import SupportGuides from './SupportGuides';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'white',
      fontSize: '14px',
      margin: theme.spacing(1),
    },
    dialogContent: {
      fontSize: '22px',
      fontStyle: 'italic',
      color: colors.gray.calm,
      marginBottom: theme.spacing(1),
    },
    input: {
      fontSize: '16px',
    },
    label: {
      fontSize: '18px',
    },
    icon: {
      fontSize: '18px',
      marginLeft: theme.spacing(1),
    },
    buttonProgress: {
      marginLeft: theme.spacing(1),
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 600,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
);

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '2px',
        borderRadius: `24px 0 24px 0`,
      },
    },
  },
})(TextField);

function AddCommentDialog(props: AddCommentDialogProps) {
  const classes = useStyles();
  const [content, setContent] = React.useState('');

  const supports = ['Informational', 'Emotional', 'Esteem', 'Network'];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.updateSupport({ ...props.supportRecords, [event.target.name]: event.target.checked });
  };

  const onClose = () => {
    setContent('');
    props.handleCommentClose();
  };

  const getSupportLinks = (topic: string) => {
    if (props.supports['informational'][topic]) {
      const links = props.supports['informational'][topic];
      const mappedLinks = links.map((link: string) => `${topic}: ${process.env.SITE_URL}${link}`);
      return mappedLinks;
    }
    return [];
  };

  return (
    <div>
      <Dialog open={props.open} onClose={onClose} maxWidth="lg" keepMounted aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Publish Comment</DialogTitle>
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12} container justify="center">
              <Typography className={classes.dialogContent}>{`“${props.row?.message}”`}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.subtitle}>Topics</Typography>
            </Grid>

            <Grid item xs={12} container justify="flex-start">
              <StaticChipsArray data={props.topics} />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.subtitle}>Required Form of Support</Typography>
            </Grid>

            <Grid item xs={12} container justify="flex-start">
              <SupportCheckBox
                supports={supports}
                checkbox={props.supportRecords}
                handleChange={handleCheckboxChange}
              />
            </Grid>

            <Grid item xs={12} lg={7} container>
              <Typography className={classes.subtitle}>Suggested Responses</Typography>

              <Grid item style={{ width: '90%', maxHeight: '30vh', overflow: 'auto' }}>
                {props.supportRecords['Informational'] &&
                  props.topics.map(topic => {
                    return getSupportLinks(topic).map((link: string) => {
                      return <SupportGuides key={link} type="informational" content={link}></SupportGuides>;
                    });
                  })}

                {props.supportRecords['Emotional'] && (
                  <SupportGuides type="emotional" content={props.supports['emotional']}></SupportGuides>
                )}

                {props.supportRecords['Esteem'] && (
                  <SupportGuides type="esteem" content={props.supports['esteem']}></SupportGuides>
                )}

                {props.supportRecords['Network'] && (
                  <SupportGuides type="network" content={props.supports['network']}></SupportGuides>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} lg={5}>
              <Typography className={classes.subtitle}>Preview</Typography>

              <CustomTextField
                autoFocus
                variant="outlined"
                onChange={onChange}
                margin="dense"
                id="name"
                fullWidth
                multiline
                rows={8}
                value={content}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            startIcon={<BlockIcon />}>
            Close
          </Button>

          <Button
            onClick={() => window.open(props.row?.link)}
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            startIcon={<FacebookIcon />}>
            Reply on Facebook
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCommentDialog;
