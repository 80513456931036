import * as React from 'react';
import { CommentCellProps, CommentHeadCell, CommentData, Order } from '../types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { formatFacebookTimestamp } from '../../../utils/timeFormatter';
import StaticChipsArray from '../../StaticChipsArray';
import { stableSort, getComparator } from '../sortHelper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LinkIcon from '@material-ui/icons/Link';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      fontSize: '12px',
      paddingLeft: 0,
      paddingRight: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    iconCell: {
      minWidth: '180px',
      maxWidth: '180px',
      margin: 0,
      padding: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerIcon: {
      color: '#212121',
      '&:hover': {
        color: '#757575',
      },
    },
    badge: {
      color: 'white',
    },
  }),
);

const headCells: CommentHeadCell[] = [
  { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
  { id: 'userName', numeric: false, disablePadding: false, label: 'User' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Comment_Updated_At' },
  { id: 'noOfComments', numeric: true, disablePadding: false, label: '#Comments' },
  { id: 'noOfReactions', numeric: true, disablePadding: false, label: '#Reactions' },
];

function CommentCell(props: CommentCellProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof CommentData>('noOfComments');

  const createSortHandler = (property: keyof CommentData) => () => {
    handleRequestSort(property);
  };

  const handleRequestSort = (property: keyof CommentData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: 'whitesmoke' }} colSpan={12}>
      <Collapse in={props.open.indexOf(props.row.id) >= 0} timeout="auto" unmountOnExit>
        <Box mx={10} mt={4} mb={6}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.id === 'noOfComments' || headCell.id === 'noOfReactions' ? 'center' : 'left'}
                    padding="none"
                    style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}
                    sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}>
                      {headCell.id === 'noOfComments' ? (
                        <Grid container direction="column">
                          <ChatBubbleOutlineIcon fontSize="small" className={classes.headerIcon} />
                        </Grid>
                      ) : headCell.id === 'noOfReactions' ? (
                        <Grid container direction="column">
                          <FavoriteBorderIcon fontSize="small" className={classes.headerIcon} />
                        </Grid>
                      ) : (
                        headCell.label
                      )}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="center" style={{ paddingRight: '32px', fontSize: '14px', fontWeight: 'bold' }}>
                  Topics
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: '120px', paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {stableSort(props.row.comments, getComparator(order, orderBy)).map(commentRow => (
                <TableRow key={commentRow.id}>
                  <TableCell className={classes.tableCell}>{commentRow.message}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {commentRow.userName
                      ? commentRow.userName
                      : props.getFbUserName(`${props.row.id}-${commentRow.id}`)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{formatFacebookTimestamp(commentRow.updatedAt)}</TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Badge
                      badgeContent={commentRow.noOfComments}
                      color="secondary"
                      showZero
                      classes={{ badge: classes.badge }}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Badge
                      badgeContent={commentRow.noOfReactions}
                      color="secondary"
                      showZero
                      classes={{ badge: classes.badge }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <StaticChipsArray data={props.getSelectedTopics(commentRow.id)} />
                  </TableCell>
                  <TableCell className={classes.iconCell}>
                    <Grid container spacing={0} direction="row">
                      <Grid item xs={4} container justify="center">
                        <Tooltip title="Manage Topics">
                          <IconButton color="primary" onClick={() => props.onClickCommentTag(commentRow)}>
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={4} container justify="center">
                        <Tooltip title="Post Comment">
                          <IconButton color="primary" onClick={() => props.onClickComment(commentRow)}>
                            <RateReviewIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={4} container justify="center">
                        <Tooltip title="View on Facebook">
                          <IconButton color="primary" onClick={() => window.open(commentRow.link)}>
                            <LinkIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  );
}

export default CommentCell;
