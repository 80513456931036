import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EnhancedTableToolbarProps } from './types';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    label: {
      '& input::placeholder': {
        fontSize: '14px',
      },
    },
    margin: {
      marginRight: theme.spacing(2),
    },
  }),
);

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const classes = useToolbarStyles();

  const onClick = () => {
    props.download();
  };

  return (
    <Toolbar className={classes.root}>
      {
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Peer Support Group
        </Typography>
      }
      {
        <Tooltip title="Export" className={classes.margin}>
          <IconButton color="primary" onClick={onClick}>
            <GetAppIcon></GetAppIcon>
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
